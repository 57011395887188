<template>
  <div v-cloak>
    <div class="px-5" />
    <div class="relative min-h-45">
      <slot name="sliderHeader" />
      <div v-if="sliderActive" class="absolute right-0 top-0 hidden lg:flex" :class="arrowContainerClass">
        <button type="button" :disabled="!prevActive" aria-label="Forrige" class="focus:outline-none disabled:opacity-50" @click="gotoPrev">
          <LazySharedIcon
            name="chevron-right"
            class="rotate-180"
          />
        </button>
        <button type="button" :disabled="!nextActive" aria-label="Næste" class="ml-5 focus:outline-none disabled:opacity-50" @click="gotoNext">
          <LazySharedIcon
            name="chevron-right"
          /></button>
      </div>
    </div>
    <div class="overflow-hidden">
      <div ref="sliderRef" class="snap-slider-scroller snap-x snap-mandatory overflow-x-scroll scroll-smooth">
        <div class="flex min-w-full">
          <SnapSliderSnapSlide
            v-for="(_, index) in props.items"
            v-cloak
            :key="index"
            :active-breakpoint="activeBreakpoint"
            :index="parseInt(index)"
            :slide-classes="sliderConf.slideClasses"
          >
            <template #slideslot>
              <slot :name="index" />
            </template>
          </SnapSliderSnapSlide>
        </div>
      </div>
    </div>
  </div>
</template>
<script lang="ts" setup>
/* eslint-disable */
// @TODO lint, move to base
const props = defineProps({
  lengthOverride: {
    type: Number,
  },
  sliderConf: {
    type: Object as PropType<Aller.SliderConf>,
    required: true,
  },
  arrowsTop: {
    type: String,
  },
  items: {
    type: Object,
    required: true,
  },
});

const emit = defineEmits(['switch']);
const { sliderHeader } = useSlots();
const hasHeader = !!sliderHeader;

const {
  sliderRef,
  currentIndex,
  activeBreakpoint,
  gotoNext,
  prevActive,
  nextActive,
  arrowContainerClass,
  resize,
  scrollListener,
  observer,
  sliderActive,
  sliderWidth
} = useAllerSlider(props, hasHeader);

/**
 * Watch index, emit when it is changed.
 */
watch(currentIndex, () => {
  emit('switch', currentIndex.value);
});

/**
 * Goto previous slide
 *
 * @returns void
 */
const gotoPrev = (): void => {
  if (prevActive.value && sliderRef.value) {
    // 100 is a magic number, ensuring the scroll-snap does not go to far left.
    console.log(sliderRef.value.scrollLeft, sliderWidth.value)
    sliderRef.value.scrollLeft -= sliderWidth.value / 2;
  }
};

/**
 * Vue mounted hook.
 * Setup listeners and resizeObserver
 *
 * @returns void
 */
onMounted((): void => {
  if (sliderRef && sliderRef.value) {
    sliderRef.value.addEventListener('scroll', () => scrollListener(), { passive: true });
    observer.value = new ResizeObserver(() => setTimeout(resize));
    observer.value.observe(sliderRef.value);
  }
});

/**
 * Vue beforeDestroy hook.
 * Disconnect the observer.
 *
 * @returns void
 */
onBeforeUnmount((): void => {
  observer.value?.disconnect();
  sliderRef.value?.removeEventListener('scroll', () => scrollListener());
});
</script>

<style scoped>
/* Hide scrollbar for Chrome, Safari and Opera */
.snap-slider-scroller::-webkit-scrollbar {
  display: none;
}

@supports (-moz-appearance:none) {
  .snap-slider-scroller {
    scroll-snap-type: x proximity;
  }
}

/* Hide scrollbar for IE, Edge and Firefox */
.snap-slider-scroller {
  -ms-overflow-style: none;
  /* IE and Edge */
  scrollbar-width: none;
  /* Firefox */
}
</style>
